/**
 * Created by tengteng on 17/12/27.
 */
import qs from 'qs';
import axios from '../service/axios';
/**
 * 统一处理所有接口请求
 * 参数对象将会替换axios中默认参数中的键值对，其中可包含：
 * method
 * timeout
 * data
 * headers
 * dataType
 */
const server = {
	goBao(data){
		return axios("/cms/generalize-bill/createGbWithLandingPage",{
			method:"post",
			data:data
		})
	},
	goPay(data){
		return axios("/pay/wechat-pay/startNativePay",{
			method:"post",
			data:data
		})
	},
	searchPay(data){
		return axios(`/pay/wechat-pay-notify/getWechatPayResult?orderSn=${data.orderSn}`,{
			method:"get",
		})
	},
	getyanz(data){
		return axios(`/sms/send`,{
			method:"post",
			data:data
		})
	},
	yanz(data){
		return axios(`/sms/verify`,{
			method:"post",
			data:data
		})
	},
	portalSign(data){
		return axios(`/cms/orders/portalSign`,{
			method:"post",
			data:data
		})
	},
}

export default server;
