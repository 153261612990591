<template>
  <div class="hello">
    <div class="stem-pc" v-if="isWeb">
      <div class="header">
        <div class="header-box">
          <img v-lazy="require('@/assets/Frame.png')" alt=""/>
          <div class="nav">
            <li class="nav-list">
              <span :class="{'active':active == 0}">首页</span>
            </li>
            <li class="nav-list">
              <span @click="goScroll('.jiaoxue')" :class="{'active':active == 1}">教学优势</span>
            </li>
            <li class="nav-list">
              <span @click="goScroll('.kecheng')" :class="{'active':active == 2}">课程体系</span>
            </li>
            <li class="nav-list">
              <span @click="goScroll('.fangfa')" :class="{'active':active == 3}">教学方法</span>
            </li>
            <li class="nav-list">
              <span @click="goScroll('.liucheng')" :class="{'active':active == 4}">上课流程</span>
            </li>
          </div>
        </div>
      </div>
      <div class="tu3 top1">
        <img v-lazy="require('@/assets/1.jpg')" alt=""/>
      </div>
      <div class="tu3">
        <div class="pin2-box">
          <div class="title">赛先生科学致力于提升青少儿学习力和创造力</div>
          <div class="pin2-nav">
            <div class="pin2-list">
              <div class="pin2-num">{{ num1 }}+</div>
              <div class="pin2-text">城市遍布学员</div>
            </div>
            <div class="pin2-list">
              <div class="pin2-num">{{ num2 }}+</div>
              <div class="pin2-text">付费学员</div>
              <div class="pin2-bottom">
                以上数据为内部统计数据
              </div>
              <div class="guan-button" @click="dialogFormVisible = true">领取STEAM体验课</div>
            </div>
            <div class="pin2-list">
              <div class="pin2-num">{{ num3 }}+</div>
              <div class="pin2-text">优质教师</div>
            </div>
          </div>
        </div>
        <img v-lazy="require('@/assets/2_2.png')" alt="">
      </div>
      <div class="kexue">
        <div class="kexue-title" style="margin-top: 70px;
    font-weight: bold;
    font-size: 36px;
    color: #000;
    margin-bottom:60px;">什么是科学课？
        </div>
        <img v-lazy="require('@/assets/3.png')" alt=""/>
      </div>
      <div class="tu4" style="padding: 50px 0 120px 0;">
        <div class="kexue-title" style="margin-top: 20px;
    font-weight: bold;
    font-size: 36px;
    color: #000;
    margin-bottom:60px;">孩子为什么要学科学
        </div>
        <img v-lazy="require('@/assets/4.png')" alt=""/>
      </div>
      <div class="tu3 jiaoxue">
        <!-- <img v-lazy="@/assets/5.png" alt=""> -->
        <div
            class="pin2-box"
            style="background-color: #f2f2f2; padding-bottom: 80px"
        >
          <div class="title" style="margin-bottom:55px;">赛先生科学核心六大优势</div>
          <div class="hexin-nav">
            <div class="box">
              <div class="pin2-list">
                <img v-lazy="require('@/assets/111.jpg')" alt=""/>
                <div class="boli">中科院旗下专注于“硬科技”中科创星机构领投</div>
              </div>
              <p>中科院旗下中科创星领投</p>
            </div>
            <div class="box">
              <div class="pin2-list">
                <img v-lazy="require('@/assets/222.jpg')" alt=""/>
                <div class="boli">
                  赛先生科学合作众多国外知名教具品牌，如Educational
                  Insights、Learning
                  Resources、Knex、Geomag等等，具有30年专业研发经验，家长认可度高，教具材质安全无毒无异味
                </div>
              </div>
              <p>全进口STEAM教具辅助</p>
            </div>
            <div class="box">
              <div class="pin2-list">
                <img v-lazy="require('@/assets/333.jpg')" alt=""/>
                <div class="boli">
                  孩子在家就能上课，更有直播互动环节，让孩子更专注
                </div>
              </div>
              <p>在线直播沉浸课程体验</p>
            </div>
            <div class="box">
              <div class="pin2-list">
                <img v-lazy="require('@/assets/666.jpg')" alt=""/>
                <div class="boli">
                  真人教师，孩子有问题，随时能应答，解决家长陪伴不专业，没时间陪伴的问题
                </div>
              </div>
              <p>真人老师精品小班教学</p>
            </div>
            <div class="box">
              <div class="pin2-list">
                <img v-lazy="require('@/assets/555.jpg')" alt=""/>
                <div class="boli">
                  赛先生课程既有美国的先进教育理念，又结合了国内的科学学科课标
                </div>
              </div>
              <p>中美团队专业研发课程</p>
            </div>
            <div class="box">
              <div class="pin2-list">

                <img v-lazy="require('@/assets/444.jpg')" alt=""/>
                <div class="boli">
                  赛先生科学带领孩子们冲击科创活动，攻打全国乃至国际性“科技大BOSS”
                </div>
              </div>
              <p>国内外科技科创活动赋能</p>
            </div>
          </div>
          <div
              class="guan-button"
              style="margin: 0 auto; margin-top: 80px"
              @click="dialogFormVisible = true"
          >
            领取STEAM体验课
          </div>
        </div>
      </div>
      <div class="tu4 kecheng">
        <div class="kexue-title" style="margin-top: 70px;
    font-weight: bold;
    font-size: 36px;
    color: #000;
    margin-bottom:80px;">赛先生科学素养五阶段课程体系
        </div>
        <img v-lazy="require('@/assets/6.png')" alt=""/>
      </div>
      <div class="tu4 fangfa" style="padding-top:70px;">
        <div class="kexue-title" style="
    font-weight: bold;
    font-size: 36px;
    color: #000;
">PBL八步教学法
        </div>
        <img v-lazy="require('@/assets/7.png')" alt=""/>
        <el-tooltip
            class="item"
            effect="light"
            content="在故事情境中，观察和比较具体现象与事物，针对现象提出问题"
            placement="bottom"
        >
          <img v-lazy="require('@/assets/b1.png')" alt="" class="yuan"/>
        </el-tooltip>
        <el-tooltip
            class="item"
            effect="light"
            content="依据已有经验，针对问题作出猜想"
            placement="bottom"
        >
          <img v-lazy="require('@/assets/b2.png')" alt="" class="yuan one"/>
        </el-tooltip>
        <el-tooltip
            class="item"
            effect="light"
            content="在教师指导下，制定探究计划"
            placement="bottom"
        >
          <img v-lazy="require('@/assets/b3.png')" alt="" class="yuan two"/>
        </el-tooltip>
        <el-tooltip
            class="item"
            effect="light"
            content="运用恰当的材料进行实验探究"
            placement="bottom"
        >
          <img v-lazy="require('@/assets/b4.png')" alt="" class="yuan three"/>
        </el-tooltip>
        <el-tooltip
            class="item"
            effect="light"
            content="通过观察与描述、比较与分类等方法得出结论"
            placement="bottom"
        >
          <img v-lazy="require('@/assets/b5.png')" alt="" class="yuan four"/>
        </el-tooltip>
        <el-tooltip
            class="item"
            effect="light"
            content="在教师的指导下，能用比较科学的词汇或符号记录整理信息"
            placement="bottom"
        >
          <img v-lazy="require('@/assets/b6.png')" alt="" class="yuan five"/>
        </el-tooltip>
        <el-tooltip
            class="item"
            effect="light"
            content="在教师的指导下，对研究结果进行分享交流"
            placement="bottom"
        >
          <img v-lazy="require('@/assets/b7.png')" alt="" class="yuan six"/>
        </el-tooltip>
        <el-tooltip
            class="item"
            effect="light"
            content="在教师的指导下，能用比较科学的词汇或符号记录整理信息"
            placement="bottom"
        >
          <img v-lazy="require('@/assets/b8.png')" alt="" class="yuan seven"/>
        </el-tooltip>
        <div
            class="guan-button"
            style="margin: 0 auto; margin-top: 120px"
            @click="dialogFormVisible = true"
        >
          领取STEAM体验课
        </div>
      </div>
      <div class="tu4" style="padding-top:70px;">
        <img v-lazy="require('@/assets/8.png')" alt=""/>
      </div>
      <div class="tu4" style="padding-top:70px;">
        <img v-lazy="require('@/assets/9.png')" alt=""/>
        <div
            class="guan-button"
            style="margin: 0 auto; margin-top: 80px;margin-bottom:70px;"
            @click="dialogFormVisible = true"
        >
          领取STEAM体验课
        </div>
      </div>
      <div
          class="tu3 liucheng"
          style="background-color: #fff9ea;"
      >
        <div class="kexue-title" style="
          font-weight: bold;
          font-size: 36px;
          color: #000;
          padding-top:120px;
          padding-bottom:65px;
      ">上课流程
        </div>
        <img v-lazy="require('@/assets/10.png')" alt=""/>

      </div>
      <div class="tu4" style="padding-top:120px;">
        <img v-lazy="require('@/assets/11.png')" alt=""/>
        <div
            class="guan-button"
            style="margin: 0 auto; margin-top: 80px"
            @click="dialogFormVisible = true"
        >
          领取STEAM体验课
        </div>
      </div>
      <div class="tu4" style="overflow:hidden;padding-top:70px;">
        <div class="test-bb" style="position:relative;">
          <swiper :options="swiperOption" ref="myswiper">
            <swiper-slide class="swiper-slide">
              <img v-lazy="require('@/assets/12.png')" alt=""/>
            </swiper-slide>

            <!-- 左右箭头 -->
          </swiper>
          <div class="swiper-button-prev" slot="button-prev" @click="prev"></div>
          <div class="swiper-button-next" slot="button-next" @click="next"></div>
        </div>
        <div
            class="guan-button"
            style="margin: 0 auto; margin-top: 80px"
            @click="dialogFormVisible = true"
        >
          领取STEAM体验课
        </div>
      </div>
      <div class="tu4">
        <div class="title" style="color: #000;margin-top:20px;">荣誉</div>
        <div class="move-big">
          <div
              class="move-ul"
              :style="{ left: calleft + 'px' }"
              @mouseover="stopmove"
              @mouseout="move"
          >
            <img v-lazy="require('@/assets/13.png')" alt="" class="move-box"/>
            <img v-lazy="require('@/assets/13.png')" alt="" class="move-box"/>
            <img v-lazy="require('@/assets/13.png')" alt="" class="move-box"/>
          </div>
        </div>
        <div
            class="guan-button"
            style="margin: 0 auto; margin-top: 0;margin-bottom:40px"
            @click="dialogFormVisible = true"
        >
          领取STEAM体验课
        </div>
      </div>
      <div class="bottom">
        <img class="bottomf" v-lazy="require('@/assets/bottom.png')" alt=""/>
        <div class="tips">
          在线领真人直播STEAM体验课
          <el-input v-model="form.phone" placeholder="请输入手机号"
          >
            <el-button
                slot="suffix"
                @click="dialogFormVisible = true"
                type="primary"
            ><span>0元</span></el-button
            >
          </el-input
          >

          <!-- <img v-lazy="@/assets/bottom_button.png" alt=""> -->
        </div>
      </div>
      <div class="bottom2">
        <div class="tips">©2020 Dr.s赛先生 沪ICP备20007659号</div>
      </div>
      <div class="fix-button one">
        官方服务号<img v-lazy="require('@/assets/fwu.jpg')" alt=""/>
      </div>
      <div class="fix-button two">
        在线咨询 <img v-lazy="require('@/assets/zy.png')" alt=""/>
      </div>
      <div class="fix-button three" @click="goTop" v-show="isShow" id="mytest">
        回到顶部
      </div>


    </div>
    <div v-else class="stem-mobile">
      <!-- 移动端的html-->
      <div class="m-banner">
        <img v-lazy="require('@/assets/1.jpg')" alt=""/>
        <img v-lazy="require('@/assets/Frame.png')" alt="" class="m-logo"/>
      </div>

      <div class="m-section m-up">
        <div class="m-section-title">赛先生科学致力于<br>提升青少儿学习力和创造力</div>
        <div class="m-section-main">
          <div class="up-list">
            <div class="up-item">
              <div class="up-item-num">200+</div>
              <div class="up-item-text">城市遍布学员</div>
            </div>
            <div class="up-item">
              <div class="up-item-num">204706+</div>
              <div class="up-item-text">付费学员</div>
            </div>
            <div class="up-item">
              <div class="up-item-num">180+</div>
              <div class="up-item-text">优质教师</div>
            </div>
          </div>
          <div class="up-tip-text">以上数据为内部统计数据</div>
        </div>
        <img v-lazy="require('@/assets/2_2.png')" alt="" class="up-footer-img">
      </div>

      <div class="m-section">
        <div class="m-section-title">什么是科学课？</div>
        <div class="m-section-main">
          <img :src="require('@/assets/3.png')" alt=""/>
        </div>
      </div>

      <div class="m-section">
        <div class="m-section-title">孩子为什么要学科学</div>
        <div class="m-section-main">
          <img v-lazy="require('@/assets/4.png')" alt=""/>
        </div>
      </div>

      <div class="m-section m-youshi">
        <div class="m-section-title">赛先生科学核心六大优势</div>
        <div class="m-section-main">
          <div class="youshi-list">
            <div class="youshi-item">
                <img v-lazy="require('@/assets/111.jpg')" alt=""/>
                <div class="ys-title">中科院旗下中科创星领投</div>
                <div class="ys-desc">中科院旗下专注于“硬科技”中科创星机构领投</div>
            </div>
            <div class="youshi-item">
              <img v-lazy="require('@/assets/222.jpg')" alt=""/>
              <div class="ys-title">全进口STEAM教具辅助</div>
              <div class="ys-desc">赛先生科学合作众多国外知名教具品牌，如Educational
                Insights、Learning
                Resources、Knex、Geomag等等，具有30年专业研发经验，家长认可度高，教具材质安全无毒无异味</div>
            </div>
            <div class="youshi-item">
              <img v-lazy="require('@/assets/333.jpg')" alt=""/>
              <div class="ys-title">在线直播沉浸课程体验</div>
              <div class="ys-desc">孩子在家就能上课，更有直播互动环节，让孩子更专注</div>
            </div>

            <div class="youshi-item">
              <img v-lazy="require('@/assets/666.jpg')" alt=""/>
              <div class="ys-title">真人老师精品小班教学</div>
              <div class="ys-desc">真人教师，孩子有问题，随时能应答，解决家长陪伴不专业，没时间陪伴的问题</div>
            </div>
            <div class="youshi-item">
              <img v-lazy="require('@/assets/555.jpg')" alt=""/>
              <div class="ys-title">中美团队专业研发课程</div>
              <div class="ys-desc">赛先生课程既有美国的先进教育理念，又结合了国内的科学学科课标</div>
            </div>
            <div class="youshi-item">
              <img v-lazy="require('@/assets/444.jpg')" alt=""/>
              <div class="ys-title">国内外科技科创活动赋能</div>
              <div class="ys-desc">赛先生科学带领孩子们冲击科创活动，攻打全国乃至国际性“科技大BOSS”</div>
            </div>

          </div>
        </div>
      </div>

      <div class="m-section m-tixi">
        <div class="m-section-title">赛先生科学素养五阶段课程体系</div>
        <div class="m-section-main">
          <img v-lazy="require('@/assets/6.png')" alt=""/>
        </div>
      </div>


      <!--   八步教学法   -->
      <div class="m-section m-eight">
        <div class="m-section-title">PBL八步教学法</div>
        <div class="m-section-main">
          <div class="teach-list">
            <div class="teach-item">
              <img v-lazy="require('@/assets/b1.png')" alt="" class="teach-num"/>
              <div class="teach-txt">在故事情境中，观察和比较具体现象与事物，针对现象提出问题</div>
            </div>
            <div class="teach-item">
              <img v-lazy="require('@/assets/b2.png')" alt="" class="teach-num"/>
              <div class="teach-txt">依据已有经验，针对问题作出猜想</div>
            </div>
            <div class="teach-item">
              <img v-lazy="require('@/assets/b3.png')" alt="" class="teach-num"/>
              <div class="teach-txt">运用恰当的材料进行实验探究</div>
            </div>
            <div class="teach-item">
              <img v-lazy="require('@/assets/b4.png')" alt="" class="teach-num"/>
              <div class="teach-txt">通过观察与描述、比较与分类等方法得出结论</div>
            </div>
            <div class="teach-item">
              <img v-lazy="require('@/assets/b5.png')" alt="" class="teach-num"/>
              <div class="teach-txt">在故事情境中，观察和比较具体现象与事物，针对现象提出问题</div>
            </div>
            <div class="teach-item">
              <img v-lazy="require('@/assets/b6.png')" alt="" class="teach-num"/>
              <div class="teach-txt">在教师的指导下，能用比较科学的词汇或符号记录整理信息</div>
            </div>
            <div class="teach-item">
              <img v-lazy="require('@/assets/b7.png')" alt="" class="teach-num"/>
              <div class="teach-txt">在教师的指导下，对研究结果进行分享交流</div>
            </div>
            <div class="teach-item">
              <img v-lazy="require('@/assets/b8.png')" alt="" class="teach-num"/>
              <div class="teach-txt">在教师的指导下，能用比较科学的词汇或符号记录整理信息</div>
            </div>
          </div>
        </div>
      </div>

      <!--   考核体系   -->
      <div class="m-section">
        <div class="m-section-main">
          <img v-lazy="require('@/assets/8.png')" alt=""/>
        </div>
      </div>
      <!--   六大理由   -->
      <div class="m-section">
        <div class="m-section-main">
          <img v-lazy="require('@/assets/9.png')" alt=""/>
        </div>
      </div>
      <!--   上课流程   -->
      <div class="m-section m-liucheng">
        <div class="m-section-title">上课流程</div>
        <div class="m-section-main">
          <img v-lazy="require('@/assets/shangkeliucheng.jpeg')" alt=""/>
        </div>
      </div>
      <!--   服务承诺   -->
      <div class="m-section">
        <div class="m-section-main">
          <img v-lazy="require('@/assets/11.png')" alt=""/>
        </div>
      </div>

      <!--   家长说   -->
      <div class="m-section">
        <div class="m-section-main">
          <img v-lazy="require('@/assets/12.png')" alt=""/>
        </div>
      </div>

      <!--   荣誉  -->
      <div class="m-section m-rongyu">
        <div class="m-section-title">荣誉</div>
        <div class="m-section-main">
          <img v-lazy="require('@/assets/rongyu1.jpeg')" alt="" />
          <img v-lazy="require('@/assets/rongyu2.jpeg')" alt="" />
        </div>

      </div>

      <div class="m-footer">
        <div class="footer-flex">
          <div class="footer-flex-item">
            <img v-lazy="require('@/assets/fwu.jpg')" alt=""/>
            <p>官方服务号</p>
          </div>
          <div class="footer-flex-item">
            <img v-lazy="require('@/assets/zy.png')" alt=""/>
            <p>在线咨询</p>
          </div>
        </div>
      </div>

      <div class="m-get" @click="dialogFormVisible = true">
        领取STEAM体验课（￥19.9）
      </div>
    </div>

    <el-dialog title="报名预约" :visible.sync="dialogFormVisible" :width="isWeb?'20%':'80%'">
      <el-form :model="form">
        <!-- <el-form-item label="活动名称" label-width="100px"> -->
        <el-input
            v-model="form.name"
            autocomplete="off"
            placeholder="孩子称呼"
        ></el-input>
        <el-date-picker
            v-model="form.birthYm"
            type="month"
            value-format="yyyy-MM"
            placeholder="孩子生日"
        >
        </el-date-picker>
        <el-input
            v-model="form.phone"
            autocomplete="off"
            placeholder="联系手机"
        ></el-input>
        <el-input v-model="form.verifyCode" autocomplete="off">
          <el-button slot="append" style="color: white;background-color: #3c8dbc;border:1px solid #3c8dbc;"
                     v-show="showTime" @click="sendEmail(form.phone)">发送验证码
          </el-button>
          <el-button slot="append" style="color: white;background-color: #3c8dbc;border:1px solid #3c8dbc;margin:0"
                     v-show="!showTime">{{ sendTime }}秒
          </el-button>
        </el-input>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="doSave" :round="true" class="tttest"
        >立即预约
        </el-button
        >
      </div>

    </el-dialog>
    <el-dialog
        @close="qxzf"
        title="微信扫码支付" :visible.sync="dialogFormVisible1" :close-on-press-escape="false" :close-on-click-modal="false"
        :width="isWeb?'50%':'90%'">
      <div class="qrcode" ref="qrCodeUrl"><img :src="wxImg" alt=""></div>
      <img src="@/assets/liucheng.png" alt="" style="width:40%;">
    </el-dialog>
    <el-dialog
        :visible.sync="dialogFormVisible2" :close-on-press-escape="false" :close-on-click-modal="false" :width="isWeb?'25%':'60%'">
      <div>
<!--        <img src="@/assets/success.png" alt="">-->
<!--        <div class="success" style="font-size:24px;font-weight:bold;color:#000;margin:10px auto;">支付成功</div>-->
        <div class="success" style="font-size:24px;font-weight:bold;color:#000;margin:10px auto;">预约成功</div>
        <img src="@/assets/fwu.jpg" alt="" style="width:100%;">
        <div class="guanzhu" style="font-size:18px;font-weight:bold;color:#000;margin:10px auto;">扫码关注服务号</div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {Message} from "element-ui";
import QRCode from "qrcode";

export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  data() {
    return {
      isWeb: false,//是不是电脑宽屏幕
      timerChangeSize: false,//避免频繁改变窗口大小
      screenWidth: document.body.clientWidth, //屏幕宽度

      carouselArr: [1, 2, 3],
      swiperOption: {
        //显示分页
        //设置点击箭头
        loop: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
          hideOnClick: true,
        },
        //开启循环模式
        // loop: true
      },
      timePay: null,
      showTime: true,
      sendTime: '', /* 倒计时 计数器 */
      timeraaa: null,
      calleft: 0,
      form: {
        name: "",
        birthYm: "",
        phone: "",
        verifyCode: ''
      },
      wxImg: '',
      dialogFormVisible1: false,
      dialogFormVisible: false,
      isShow: false,
      active: 0,
      dialogFormVisible2: false,
      num1: 0,
      num2: 0,
      num3: 0,
      targetDom: null, // 当前滚动对象
      showBackTop: false, // 是否显示回到顶部标识
      scrollY: 0, // 滚动距离
    };
  },
  methods: {
    prev() {
      console.log('点击了')
      this.$refs.myswiper.$swiper.slidePrev();
    },
    next() {
      this.$refs.myswiper.$swiper.slideNext();
    },
    __genQrCodeWithParam(orderSn, url) {
      console.log('urlc', url)
      QRCode.toDataURL(url).then(
          url => {
            this.wxImg = url;
            this.dialogFormVisible1 = true
          }
      );
      this.timePay = setInterval(() => {
        this.$server.searchPay({orderSn: orderSn}).then(res => {
          console.log(res, '支付状态')
          if (res.data.resultCode == 'SUCCESS') {
            this.dialogFormVisible1 = false
            this.zuihouBao()
            clearInterval(this.timePay);
          }
        })
      }, 2000);

    },
    qxzf() {
      this.form = this.$options.data().form;
      clearInterval(this.timePay);
    },
    handleScroll(e) {
      this.scrollY = e.target.scrollTop;
      this.showBackTop = e.target.scrollTop > 100; // 页面滚动距离大于100的时候显示回到top的标识
      this.targetDom = e;
    },
    goScroll(val) {
      var test = document.querySelector(val);
      console.log(test.offsetTop);
      let top = (document.documentElement.scrollTop || document.body.scrollTop);
      var flag = true // true下降false上升
      if (top > test.offsetTop) {
        flag = false
      } else {
        flag = true
      }
      const timeTop = setInterval(() => {
        if (flag) {
          document.body.scrollTop =
              document.documentElement.scrollTop =
                  top +=
                      50;
        } else {
          document.body.scrollTop =
              document.documentElement.scrollTop =
                  top -=
                      50;
        }
        console.log(test.offsetTop, "top--");
        if (top >= (test.offsetTop - 102) && flag) {
          clearInterval(timeTop);
        } else if (top <= (test.offsetTop - 102) && !flag) {
          clearInterval(timeTop);
        }
      }, 10);
    },
    doSave() {
      if (this.form.name == "") {
        Message.error("请输入孩子称呼");
        return;
      }
      if (this.form.birthYm == "") {
        Message.error("请选择孩子生日");
        return;
      }
      if (this.form.phone == "") {
        Message.error("请输入手机号");
        return;
      }
      var rel =
          /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      console.log(rel.test(this.form.phone));
      if (!rel.test(this.form.phone)) {
        Message.error("请输入格式正确的手机号");
        return;
      }
      this.$server.yanz({phone: this.form.phone, verifyCode: this.form.verifyCode}).then(res => {
        console.log('验证验证码')
        if (res.status == 200) {
          this.dialogFormVisible = false;
          let loading = this.$loading({
            lock: true,
            text: "报名中",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          this.$server.portalSign({
            mobile: this.form.phone,
            truename: this.form.name,
            birthYm: this.form.birthYm
          }).then(re => {
            console.log(re, '新的报名接口')
            if (re.status == 200) {
              loading.close();
              // this.$server.goPay({orderSn: re.data.message.orderSn}).then(ree => {
              //   console.log('二维码', ree)
                this.zuihouBao()
                // this.__genQrCodeWithParam(ree.data.orderSn, ree.data.codeUrl)
              // })
            }
          }).catch(err => {
            console.log(err)
            loading.close();
            Message.error('您已报过名，请联系赛先生老师');
          })

        } else {
          Message.error("验证码输入错误");
        }
      }).catch(reason => {
        Message.error("验证码不正确");
      })

    },
    zuihouBao() {
      let obj = {
        birthYm: this.form.birthYm,
        clickParam: "",
        courseSpuName: "在线科学实验体验课",
        faliReason: "",
        genSource: 1,
        latestLessonTime: "",
        paySource: "",
        platform: 0,
        promotionPlanId: "1169",
        promotionPlanName: "",
        proposerMobile: this.form.phone,
        proposerName: this.form.name,
        receiptAmount: 19.9,
        receiveAddress: "",
        receiveArea: "",
        receiveCity: "",
        receiveMobile: "",
        receiveName: "",
        receiveProvince: "",
        referrerMobile: "",
        referrerName: "",
        sellerId: "",
        sellerName: "",
        strategy: 1,
        sync800refer: false,
      };

      this.$server.goBao(obj).then((res) => {
        console.log(res);
        this.form = this.$options.data().form;
        if (res.status == 200) {
          this.dialogFormVisible2 = true
          _hmt.push(["_trackEvent", "推广", "报名"]);
        } else {
          Message.error("您已报过名，请联系赛先生老师");
        }
      }).catch(err=>{
        console.log("错误")
      });
    },
    goTop() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      // 实现滚动效果
      const timeTop = setInterval(() => {
        document.body.scrollTop =
            document.documentElement.scrollTop =
                top -=
                    50;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
    },
    /*发送验证码时，开始计数，一分钟发送一次*/
    sendEmail(phone) {
      var rel =
          /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      console.log(rel.test(phone));
      if (!rel.test(phone)) {
        Message.error("请输入格式正确的手机号");
        return;
      }
      this.$server.getyanz({phone: phone}).then((res) => {
        console.log(res);
      });
      const TIME_COUNT = 60; //  更改倒计时时间
      if (!this.timeraaa) {
        console.log('??')
        this.sendTime = TIME_COUNT;
        this.showTime = false;
        this.timeraaa = setInterval(() => {
          console.log(this.sendTime, 'sendTime')
          if (this.sendTime > 0 && this.sendTime <= TIME_COUNT) {
            this.sendTime--;
          } else {
            this.showTime = true;
            clearInterval(this.timeraaa); // 清除定时器
            this.timeraaa = null;
          }
        }, 1000);
      }
    },
    // move() {
    //   this.timer = setInterval(this.starmove, 30);
    // },
    starmove() {
      // 340是根据自己图片的大小自己设置的，点击一下整体向左平移多少
      this.calleft -= 5;

      if (
          this.calleft < -(document.querySelectorAll(".move-box")[0].width * 2)
      ) {
        this.calleft = 0;
      }
    },
    move() {
      this.timer = setInterval(this.starmove, 30);
    },
    //鼠标悬停时停止移动
    stopmove() {
      console.log("/???");
      clearInterval(this.timer);
    },
  },
  mounted() {
    let that = this;
    _hmt.push(['_trackPageview', '/home']);

    //判断当前窗口大小
    if (document.body.clientWidth >= 1100) {
      that.isWeb = true;
    } else {
      that.isWeb = false;
    }
    //窗口变化先要在加载页面的时候挂载一下
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth;
        that.screenWidth = window.screenWidth;
      })()
    }

    // if(that.isWeb){
    //   that.move();
    //   window.addEventListener("scroll", this.handleScroll, true);
    //
    //   window.onscroll = function () {
    //     var scrollTop =
    //         document.documentElement.scrollTop || document.body.scrollTop;
    //     //变量windowHeight是可视区的高度
    //     var windowHeight =
    //         document.documentElement.clientHeight || document.body.clientHeight;
    //     //变量scrollHeight是滚动条的总高度
    //     var scrollHeight =
    //         document.documentElement.scrollHeight || document.body.scrollHeight;
    //     //滚动条到底部的条件
    //     let bannerHeight = document.querySelector(".pin2-box").scrollHeight;
    //     let bannerHeight1 = document.querySelector(".pin2-box").offsetHeight;
    //     let bannerTop = document.querySelector(".pin2-box").offsetTop; //屏幕的高度
    //     // 　　　　　　let screenHeight = window.screen.availHeight
    //     // console.log( document.querySelector('.jiaoxue'),'scrollTop---')
    //     if (scrollTop >= document.querySelector('.jiaoxue').offsetTop - 102 && scrollTop < document.querySelector('.kecheng').offsetTop - 102) {
    //       that.active = 1
    //     } else if (scrollTop >= document.querySelector('.kecheng').offsetTop - 102 && scrollTop < document.querySelector('.fangfa').offsetTop - 102) {
    //       that.active = 2
    //     } else if (scrollTop >= document.querySelector('.fangfa').offsetTop - 102 && scrollTop < document.querySelector('.liucheng').offsetTop - 102) {
    //       that.active = 3
    //     } else if (scrollTop >= document.querySelector('.liucheng').offsetTop - 102) {
    //       that.active = 4
    //     } else {
    //       that.active = 0
    //     }
    //     if (
    //         bannerTop - bannerHeight1 < scrollTop &&
    //         scrollTop <= bannerTop + bannerHeight
    //     ) {
    //       // console.log("只想了吗");
    //       if (that.num2 == 2000000) {
    //
    //       } else {
    //         const test = setInterval(() => {
    //           if (that.num1 < 200) {
    //             that.num1 += 4;
    //           }
    //           if (that.num2 < 200000) {
    //             that.num2 += 5387;
    //           }
    //           if (that.num3 < 180) {
    //             that.num3 += 2;
    //           }
    //           if (that.num1 > 200) {
    //             that.num1 = 200
    //           }
    //           if (that.num2 > 2000000) {
    //             that.num2 = 2000000
    //             clearInterval(test)
    //           }
    //           if (that.num3 > 180) {
    //             that.num3 = 180
    //           }
    //         }, 120);
    //       }
    //     }
    //     if (scrollTop + windowHeight == scrollHeight) {
    //       //写后台加载数据的函数
    //       document.querySelector(".bottom").style = "bottom:50px;";
    //     } else {
    //       document.querySelector(".bottom").style = "bottom:0;";
    //     }
    //     // 为了保证兼容性，这里取两个值，哪个有值取哪一个
    //     var scrollTop =
    //         document.documentElement.scrollTop || document.body.scrollTop;
    //     if (scrollTop > 102) {
    //       document.querySelector('.header').style = " box-shadow: 10px 1px 5px #888888;"
    //       document.querySelector('.top1').style = 'margin-top:0;'
    //     } else {
    //       document.querySelector('.header').style = " box-shadow: none;"
    //       document.querySelector('.top1').style = 'margin-top:102px;'
    //     }
    //     if (scrollTop > 1300) {
    //       that.isShow = true;
    //     } else {
    //       that.isShow = false;
    //     }
    //   };
    // }

  },
  watch: {
    isWeb(val,old){
      let that = this;
      if(val){
        that.move();
        window.addEventListener("scroll", this.handleScroll, true);

        window.onscroll = function () {
          var scrollTop =
              document.documentElement.scrollTop || document.body.scrollTop;
          //变量windowHeight是可视区的高度
          var windowHeight =
              document.documentElement.clientHeight || document.body.clientHeight;
          //变量scrollHeight是滚动条的总高度
          var scrollHeight =
              document.documentElement.scrollHeight || document.body.scrollHeight;
          //滚动条到底部的条件
          let bannerHeight = document.querySelector(".pin2-box").scrollHeight;
          let bannerHeight1 = document.querySelector(".pin2-box").offsetHeight;
          let bannerTop = document.querySelector(".pin2-box").offsetTop; //屏幕的高度
          // 　　　　　　let screenHeight = window.screen.availHeight
          // console.log( document.querySelector('.jiaoxue'),'scrollTop---')
          if (scrollTop >= document.querySelector('.jiaoxue').offsetTop - 102 && scrollTop < document.querySelector('.kecheng').offsetTop - 102) {
            that.active = 1
          } else if (scrollTop >= document.querySelector('.kecheng').offsetTop - 102 && scrollTop < document.querySelector('.fangfa').offsetTop - 102) {
            that.active = 2
          } else if (scrollTop >= document.querySelector('.fangfa').offsetTop - 102 && scrollTop < document.querySelector('.liucheng').offsetTop - 102) {
            that.active = 3
          } else if (scrollTop >= document.querySelector('.liucheng').offsetTop - 102) {
            that.active = 4
          } else {
            that.active = 0
          }
          if (
              bannerTop - bannerHeight1 < scrollTop &&
              scrollTop <= bannerTop + bannerHeight
          ) {
            // console.log("只想了吗");
            if (that.num2 == 2000000) {

            } else {
              const test = setInterval(() => {
                if (that.num1 < 200) {
                  that.num1 += 4;
                }
                if (that.num2 < 200000) {
                  that.num2 += 5387;
                }
                if (that.num3 < 180) {
                  that.num3 += 2;
                }
                if (that.num1 > 200) {
                  that.num1 = 200
                }
                if (that.num2 > 2000000) {
                  that.num2 = 2000000
                  clearInterval(test)
                }
                if (that.num3 > 180) {
                  that.num3 = 180
                }
              }, 120);
            }
          }
          if (scrollTop + windowHeight == scrollHeight) {
            //写后台加载数据的函数
            document.querySelector(".bottom").style = "bottom:50px;";
          } else {
            document.querySelector(".bottom").style = "bottom:0;";
          }
          // 为了保证兼容性，这里取两个值，哪个有值取哪一个
          var scrollTop =
              document.documentElement.scrollTop || document.body.scrollTop;
          if (scrollTop > 102) {
            document.querySelector('.header').style = " box-shadow: 10px 1px 5px #888888;"
            document.querySelector('.top1').style = 'margin-top:0;'
          } else {
            document.querySelector('.header').style = " box-shadow: none;"
            document.querySelector('.top1').style = 'margin-top:102px;'
          }
          if (scrollTop > 1300) {
            that.isShow = true;
          } else {
            that.isShow = false;
          }
        };
      }else{
        that.stopmove()
        window.removeEventListener('scroll', this.handleScroll, false);
      }
    },
    //监听窗口变化
    screenWidth(val) {
      if (!this.timerChangeSize) {
        this.screenWidth = val;
        this.timerChangeSize = true;
        let that = this;
        if (this.screenWidth >= 1000) {
          this.isWeb = true;
        } else {
          this.isWeb = false;
        }
        //避免频繁的改变窗口大小造成页面卡顿
        setTimeout(function () {
          that.timerChangeSize = false;
        }, 400)
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.test-bb {
  // display: flex;
  // align-items: center;
}

.test-bb /deep/ .swiper-button-prev {
  color: #E96B2E;
  left: 10%;
  z-index: 99;
  cursor: pointer;
}

.test-bb /deep/ .swiper-button-next {
  color: #E96B2E;
  right: 10%;
  z-index: 99;
  cursor: pointer;
}

h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.hello {
  width: 100%;

  .bottom2 {
    height: 50px;
    background-color: #333;

    .tips {
      line-height: 50px;
      text-align: center;
      font-size: 14px;
      color: #ccc;
    }
  }

  .bottom {
    width: 100%;
    position: fixed;
    bottom: 0;
    background-color: #ffbd24;
    height: 70px;
    z-index: 999;

    .tips {
      display: flex;
      justify-content: center;
      float: left;
      color: #fff;
      font-size: 22px;
      font-weight: bold;
      text-align: center;
      line-height: 70px;
      width: 100%;

      & /deep/ .el-input {
        width: 25%;
        margin-left: 30px;
        display: flex;
        align-items: center;

        .el-input__inner {
          border-radius: 30px;
          border: none;
        }
      }

      & /deep/ .el-input__suffix {
        height: 40px !important;
        top: 50%;
        margin-top: -20px;
        right: 0;
      }

      & /deep/ .el-input__suffix-inner {
        display: flex;
        height: 100%;
        align-items: center;
      }

      .el-button {
        width: 80px;
        background-color: #e54507;
        border: 1px solid #e54507;
        border-radius: 0 30px 30px 0;
        padding: 0;
        height: 40px;
        font-size: 18px;
        font-weight: bold;
        line-height: normal;
      }
    }

    .bottomf {
      bottom: 0;
      width: 150px;
      position: absolute;
      left: 15%;
    }
  }

  .title {
    font-size: 36px;
    font-weight: bold;
    // margin-bottom: 50px;
  }

  .move-big {
    width: 60%;
    margin: 0 auto;
    overflow: hidden;
  }

  .move-ul {
    position: relative;
    min-width: 100px;
    display: flex;

    .move-box {
      float: left;
      // width: 600px;
      width: 100%;
    }
  }

  & /deep/ .el-dialog {
    border-radius: 20px;
    min-width: 20%;
    // max-width: 20%;
    .qrcode {
      margin: 0 auto;
      width: 45%;
    }

    .el-date-editor.el-input {
      width: 100%;
    }

    .el-dialog__title {
      font-size: 28px;
      font-weight: bold;
    }

    .el-dialog__body {
      padding: 10px 20px;
    }

    .el-input {
      margin: 10px 0;
    }

    .dialog-footer {
      text-align: center;
    }

    & /deep/ .el-input-group__append {
      padding: 0;
    }

    .tttest {
      width: 60%;
      // height: 50px;
      background-color: #e54507;
      border: 1px solid #e54507;
      // font-size:18px;
      // color: #fff;
      // text-align: center;
      // display: flex;
      // line-height: 50px;
      // border-radius: 50px;
      // margin: 0 auto;
    }
  }

  .fangfa {
    position: relative;

    .yuan {
      position: absolute;
      width: 110px;
      height: 110px;
      left: 25%;
      top: 24%;
      // opacity: 0;
      cursor: pointer;
    }

    .yuan.one {
      left: 40%;
      top: 20%;
    }

    .yuan.two {
      left: 57%;
      top: 21%;
    }

    .yuan.three {
      left: 76%;
      top: 31%;
    }

    .yuan.four {
      left: 63%;
      top: 48%;
    }

    .yuan.five {
      left: 47%;
      top: 53%;
    }

    .yuan.six {
      left: 56%;
      top: 66%;
    }

    .yuan.seven {
      left: 72%;
      top: 62%;
    }
  }

  .fix-button {
    cursor: pointer;
    position: fixed;
    right: 0;
    bottom: 25%;
    width: 150px;
    background-color: #ffbd24;
    color: #fff;
    font-size: 16px;
    line-height: 50px;
    height: 50px;
    border-radius: 90px 0px 0px 90px;
    z-index: 1001;

    img {
      display: none;
      position: absolute;
      left: -160px;
      top: -20px;
      width: 150px;
    }
  }

  .fix-button:hover {
    img {
      display: block;
    }
  }

  .fix-button.two {
    background-color: #e54507;
    bottom: 18%;
    cursor: pointer;

    img {
      display: none;
      position: absolute;
      left: -160px;
      top: -100px;
      width: 150px;
    }
  }

  .fix-button.two:hover {
    img {
      display: block;
    }
  }

  .fix-button.three {
    background-color: #97dd01;
    bottom: 11%;
  }

  .guan-button {
    cursor: pointer;
    width: 350px;
    height: 70px;
    background-color: #e54507;
    font-size: 28px;
    color: #fff;
    text-align: center;
    line-height: 70px;
    border-radius: 50px;
    margin-top: 60px;
  }

  .guan-button:hover {
    background-color: #c23904;
  }

  .guan-button2 {
    cursor: pointer;
    width: 350px;
    height: 70px;
    background-color: #e54507;
    font-size: 24px;
    color: #fff;
    text-align: center;
    line-height: 70px;
    border-radius: 70px;
    position: absolute;
    left: 45%;
    bottom: 10%;
  }

  .guan-button2:hover {
    background-color: #c23904;
  }

  .pin2-box {
    width: 100%;
    color: #000;
    padding-top: 120px;

    .title {
      font-size: 36px;
      font-weight: bold;
      margin-bottom: 50px;
    }

    .pin2-nav {
      width: 65%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;

      .pin2-num {
        font-size: 32px;
        font-weight: bold;
        margin: 15px 0;
      }

      .pin2-text {
        font-size: 24px;
      }
    }

    .pin2-bottom {
      font-size: 12px;
      margin-top: 80px;
    }
  }

  .header {
    width: 100%;
    height: 62px;
    padding: 20px 0;
    position: fixed;
    top: 0;
    background-color: #fff;
    z-index: 2;

    .header-box {
      width: 70%;
      height: 62px;
      margin: 0 auto;
      font-size: 20px;
      font-weight: bold;
      color: #000;

      .nav {
        float: left;
        margin-left: 5%;
        display: flex;
        width: 60%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .nav-list {
          cursor: pointer;
          float: left;
          line-height: 62px;
          color: #000;

          .active {
            color: #e54507;
            border-bottom: 3px solid #e54507;
            padding-bottom: 15px;
          }
        }

        .nav-list:hover {
          span {
            border-bottom: 3px solid #e54507;
            padding-bottom: 15px;
            color: #e54507;
          }
        }
      }

      img {
        height: 62px;
        float: left;
      }
    }
  }

  .hexin-nav {
    width: 65%;
    display: flex;
    margin: 0 auto;
    flex-wrap: wrap;
    justify-content: space-between;

    .box {
      width: 30%;
      margin-bottom: 10px;
    }

    .pin2-list {
      width: 100%;

      position: relative;
    }

    .boli {
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      color: #fff;
      box-sizing: border-box;
      padding: 10px;
      text-align: left;
      background: rgba(0, 0, 0, 0.4);
      display: none;
    }

    .pin2-list:hover .boli {
      display: block;
    }
  }

  .kexue {
    width: 100%;
    padding: 50px 0;
    position: relative;

    img {
      width: 60%;
      margin: 0 auto;
    }
  }

  .tu3 {
    img {
      width: 100%;
    }
  }

  .tu4 {
    width: 100%;
    padding: 50px 0;

    img {
      width: 60%;
      margin: 0 auto;
    }
  }
}

.stem-pc{
  min-width: 1110px;
  width: auto;
  overflow-x: auto;
}
//移动端（宽度<1100px时）的样式
.stem-mobile{
  position: relative;
  img{
    width: 100%;
    display: block;
  }
  .m-banner{
    width: 100%;
    position: relative;

    .m-logo{
      position: absolute;
      top:5px;
      left:5px;
      width: 25%;
    }
  }
  .m-section{
    padding:20px 0;
    .m-section-title{
      font-size: 20px;
      font-weight: bold;
      line-height: 40px;
      padding:16px 0;
      text-align: center;
    }
    .m-section-main{
      width: 100%;
      padding:16px 20px;
      box-sizing: border-box;
    }
  }
  .m-up{
    .m-section-title{
      font-size: 20px;
      line-height: 30px;
    }
    .up-list{
      display: flex;
      padding-bottom: 40px;
      .up-item{
        flex:1;
        .up-item-num{
          font-size: 24px;
          font-weight: 700;
          margin: 15px 0;
        }
        .up-item-txt{
          font-size: 18px;
          font-weight: normal;
          margin: 15px 0;
        }
      }
    }
    .up-tip-text{
      font-size: 13px;
      color:#888;

    }
  }
  .m-youshi{
    background: #f2f2f2;

    .m-section-main{
      padding:16px 0;
    }
    .youshi-list{
      display: flex;
      flex-wrap: wrap;
      align-content: space-between;
      .youshi-item{
        width: 50%;
        padding:0 10px;
        box-sizing: border-box;
        img{
          margin:20px auto;
          width: 80%;
        }
        .ys-title{
          font-size:17px;
          color:#000;
          line-height: 26px;
          min-height: 55px;
        }
        .ys-desc{
          font-size: 14px;
          color:#777;
          line-height: 22px;
          min-height: 66px;
          text-align: center;
        }
      }
    }
  }
  .m-eight{
    .teach-list{
      display: flex;
      flex-wrap: wrap;
      align-content: space-between;
      .teach-item{
        width: 50%;
        .teach-num{
          margin:10px auto;
          width: 50%;
        }
        .teach-txt{
          padding: 0 10px 20px;
          text-align: center;
          font-size: 16px;
          line-height: 22px;
          min-height: 50px;
        }
      }
    }
  }
  .m-liucheng{
    background: #fff9ea;
    padding-bottom: 30px;
  }
  .m-rongyu{

  }
  .m-footer{
    background: #f2f2f2;
    padding: 40px 0 100px;
    font-size:15px;
    color:#333;
    .footer-flex{
      display: flex;
      .footer-flex-item{
        flex:1;
        text-align: center;
        img{
          margin: 0 auto 15px;
          width: 50%;
          border: 1px solid #f2f2f2;
          border-radius: 4px;
        }
      }
    }
  }
  .m-get{
    position: fixed;
    left:0;
    right:0;
    bottom:0;
    background: #ffbd24;
    width: 100%;
    height: 60px;
    line-height: 60px;
    font-size: 20px;
    color:#fff;
    font-weight: bold;
    animation: fontChange .6s infinite linear;
    -webkit-animation: fontChange .6s infinite linear;
    cursor: pointer;
  }

}
@keyframes fontChange {
  0% {
    font-size: 20px
  }
  50%{
    font-size: 15px
  }
  100% {
    font-size: 20px
  }

}
</style>
