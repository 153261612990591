// axios
/**
 * Created by tengteng on 17/12/27.
 */
import axios from 'axios';
import baseUrl from '../config';
import { Message } from 'element-ui';
axios.defaults.withCredentials=true
// 添加请求拦截器
axios.interceptors.request.use(function (config) {
	console.log(config)
	return config;
}, function (error) {
	// 预处理请求错误（error）
	return Promise.reject(error);
});

// 添加响应拦截器
axios.interceptors.response.use(function (response) {
	// console.log(response.data);
	return response;
}, function (error) {
	// 预处理响应错误（error)
	// alert(error);
	return Promise.reject(checkStatus(error));
});

/**
 * 状态码校验
 * @param response
 * @return {*}
 */
function checkStatus(response) {
	// 有响应时校验状态
	if (response) {
		console.log(response,'res---xxx')
		// -1000 自己定义，连接错误的status
		// const status = response.status || -1000;
		return response.data;

		// 	Message.error('网络请求失败');
		// 	let errorInfo = '';
		// 	switch (status) {
		// 		case -1:
		// 			errorInfo = '远程服务响应失败,请稍后重试';
		// 			break;
		// 		case 400:
		// 			errorInfo = '400: 错误请求';
		// 			break;
		// 		case 401:
		// 			errorInfo = '401: 访问令牌无效或已过期';
		// 			break;
		// 		case 403:
		// 			errorInfo = '403: 拒绝访问';
		// 			break;
		// 		case 404:
		// 			errorInfo = '404：资源不存在';
		// 			break;
		// 		case 405:
		// 			errorInfo = '405: 请求方法未允许';
		// 			break;
		// 		case 408:
		// 			errorInfo = '408: 请求超时';
		// 			break;
		// 		case 500:
		// 			errorInfo = '500：访问服务失败';
		// 			break;
		// 		case 501:
		// 			errorInfo = '501：未实现';
		// 			break;
		// 		case 502:
		// 			errorInfo = '502：无效网关';
		// 			break;
		// 		case 503:
		// 			errorInfo = '503: 服务不可用';
		// 			break;
		// 		default:
		// 			errorInfo = `连接错误${status}`
		// 	}
		// 	return {status, msg: errorInfo}
		// }
	}else{
		return {data:'ce '}
	}
	// 异常状态下，返回错误信息
	return {status: -404, msg: '网络异常'};
}

/**
 * 返回axios方法
 * @param url（如果传绝对地址则baseURL不会追加到url之前）
 * @param method
 * @param timeout
 * @param data
 * @param headers
 * @param dataType
 * @returns {AxiosPromise}
 */
export default function(url, {
	// 不传时，默认参数
	method = 'get',
	timeout = 10000000,
	data = {},
	headers = {'Content-Type': 'application/json'}, // 'Content-Type': 'application/json' 可以扩展，扩展规则参考http://tool.oschina.net/commons/ application/x-www-form-urlencoded
	dataType = 'json' // 若是上传其他类型的数据，需要进行重定义，如图片与文件上传
}) {
	const config = {
		method: method,
		timeout: timeout,
		url: url, // 如果URL是完整的，包含域名，则下方的域名不会被拼接
		baseURL: baseUrl.URL_CNODEJS, // 域名，在最外层的config.js当中可以修改，请求目标服务器域名配置，结合我们自己的项目，在项目放置到服务器上时，就是将域名替换成192.168.1.3
		data: data,
		headers: headers,
		dataType: dataType
	};
	// console.log(config.data);
	return axios(config);
}
